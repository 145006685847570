import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Icon } from '@components';
import { device } from '@styles';

const StyledHeader = styled.div`
    position: relative;
    & > * {
        margin-bottom: 48px;

        :first-child {
            margin-bottom: 24px;
        }
    }

    @media ${device.tablet} {
        & > h5 {
            padding-top: 50px;
        }
    }

    p {
        color: var(--orange);
    }

    h2 {
        font-size: 48px;
        line-height: 120%;
        font-weight: 800;
    }
`;

const Bar = styled.div``;

const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
        line-height: 150%;
        font-weight: 700;
    }

    & > :nth-child(2) {
        padding: 0 10px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: 18px;
    height: 35px;
    width: 35px;
    background: hsl(206, 14%, 17%);

    svg {
        width: 10px;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-of-type) {
        margin-right: 8px;
    }

    p {
        font-size: 14px;
        line-height: 150%;
        font-weight: 400;
    }
`;

const Tags = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;

    @media ${device.tablet} {
        position: initial;
        margin-top: 24px;
    }
`;

type HeaderProps = {
    title: string;
    date: string;
    tags: string[];
};

const Header: React.FC<HeaderProps> = ({ date, title, tags }) => {
    const mapTags = useMemo(
        () => tags.map(tag => <p key={tag}>{`#${tag}`}</p>),
        [tags]
    );

    return (
        <StyledHeader>
            <h2>{title}</h2>
            <Bar>
                <Container>
                    <IconWrapper>
                        <Icon name="blog" />
                    </IconWrapper>
                    <div>
                        <h5>MK</h5>
                        <Container>
                            <p>
                                {new Date(date).toLocaleDateString('en-UK', {
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                })}
                            </p>
                            <p>&bull;</p>
                            <p>5 min read</p>
                        </Container>
                    </div>
                </Container>
                <Tags>
                    <div>
                        <h5>Tags</h5>
                        <Container>{mapTags}</Container>
                    </div>
                </Tags>
            </Bar>
        </StyledHeader>
    );
};

export default Header;

import GATSBY_COMPILED_MDX from "/home/minimaniac/Projects/WebDev/website/content/posts/markdown-playground/index.mdx";
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Blog as Layout} from '@layouts';
import Header from './Header';
var Content = styled.div.withConfig({
  displayName: "Post__Content",
  componentId: "sc-1yhi43c-0"
})(["& > *{margin:2em 0;}h1,h2,h4,h5,h6{margin:2em 0 1em;}p{margin:1em 0;font-size:16px;line-height:1.5;}ul,li{line-height:160%;color:var(--p-color);}a{color:var(--orange) !important;}ul,ol{padding-inline-start:40px;}code{background-color:var(--gray);border-radius:4px;font-size:14px;padding:0.2em 0.4em;}"]);
var Post = function Post(_ref) {
  var data = _ref.data, children = _ref.children;
  var _useState = useState(), sections = _useState[0], setSections = _useState[1];
  var ref = useRef(null);
  var frontmatter = data.mdx.frontmatter;
  var title = frontmatter.title, date = frontmatter.date, tags = frontmatter.tags, slug = frontmatter.slug;
  useEffect(function () {
    var _ref$current;
    var list = [];
    ref === null || ref === void 0 ? void 0 : (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.querySelectorAll('section').forEach(function (section) {
      return list.push(section);
    });
    setSections(list);
  }, [ref]);
  return React.createElement(Layout, {
    withContent: true,
    sections: sections,
    title: slug
  }, React.createElement(Header, {
    title: title,
    date: date,
    tags: tags
  }), React.createElement(Content, {
    ref: ref
  }, children));
};
var PostQuery = "2698899031";
Post
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Post, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
